<template>
  <div>
    <div class="mb-5 flex justify-between items-center">
      <div class="text-lg font-bold">Business Information</div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Business Name
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ businessName }}
      </div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Business Description
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ businessDescription }}
      </div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Business Certificate
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ businessCertificate }}
      </div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        General Email
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ generalEmail }}
      </div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Support Email
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ supportEmail }}
      </div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Dispute Phone Number
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ disputeEmail }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    businessInformation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    businessName() {
      return this.businessInformation?.business_name ?? '----';
    },
    businessDescription() {
      return this.businessInformation?.business_description ?? '----';
    },
    businessCertificate() {
      return this.businessInformation?.business_certificate ?? '----';
    },
    generalEmail() {
      return this.businessInformation?.general_email ?? '----';
    },
    supportEmail() {
      return this.businessInformation?.support_email ?? '----';
    },
    disputeEmail() {
      return this.businessInformation?.dispute_email ?? '----';
    }
  }
};
</script>
